import React from 'react'
import { Link } from "gatsby";
import './offerCard.scss'
import {formatDate, formatDevURL, setTrim } from '../utility/offerFunctions'
import placeHolderImageSrc from './specialsPlaceholder.jpg'

const OfferCard = (props) => {
    const {offer, liveActionShots, placeholder, setContentType, cpo} = props
    const placeHolderImage = <img src={placeHolderImageSrc} alt="Aston Martin Specials" />
    const handleExpand = (e) => {
        e.currentTarget.classList.toggle('open')
        e.currentTarget.nextSibling.classList.toggle('expand')
    }

    return (
        <div className="special__container">
            <div className="special__image">
                {placeholder ? placeHolderImage : 
                <img alt={`${offer.year ? offer.year : ''} ${offer.make} ${offer.model} Special`}
                    title={`${offer.year ? offer.year : ''} ${offer.make} ${offer.model} Special`}
                    className="special__image-background"
                    src={formatDevURL(offer.background_url)} />}
                {placeholder ? '' : (liveActionShots)
                    || <img alt={`${offer.year ? offer.year : ''} ${offer.make} ${offer.model}`}
                        title={`${offer.year ? offer.year : ''} ${offer.make} ${offer.model} Special`}
                        className="special__image-jellybean"
                        src={formatDevURL(offer.photo_url)} />}
            </div>
            <div className="special__info">
                {!placeholder
                    // ? <h2 className="title"> {(offer.cpoOffers || cpo) ? '' : 'New'} {offer.year} {offer.make} {offer.model}</h2>
                    ? <h2 className="title"> {(offer.year == null || offer.year < 2024 ) ? 'Pre-Owned' : 'New'} {offer.year} {offer.make} {offer.model}</h2>
                    : <h2 className="title">New {offer.year} Aston Martin {offer.model}</h2>}
                {(!placeholder && offer.trim !== '') && <p className="sub-title">{offer.trim}</p>}
                {!placeholder
                    ? 'apr' in offer
                        ? <div className="special__info-price">
                            <div className="apr"><p>{offer.apr}%</p></div>
                            <div className="term"><p>APR FINANCING<span className='termSpan'><br/>FOR {offer.term} MONTHS</span></p></div>
                        </div>
                    : 'payment' in offer
                        ? <div>
                            <div className="special__info-price">
                                <div className="payment"><p>${offer.payment.toLocaleString()}</p></div>
                                <div className="months"><p>LEASE PAYMENT<span className='termSpan'><br/>FOR {offer.term} MONTHS</span></p></div>
                            </div>
                                {offer.down > 0 ?
                                    <div className="down"><p>${offer.down > 0 && offer.down.toLocaleString()} <span
                                        className="down__text">DUE AT SIGNING</span></p></div>
                                    : <></>
                                }
                        </div>
                    : 'offMsrp' in offer
                        ? <div className="special__info-price">
                            <div className="cash"><p>${offer.offMsrp.toLocaleString()}</p></div>
                            <div className="offMsrp"><p>OFF<br/>MSRP</p></div>
                        </div>
                        : ''
                    : ''}
                <div className="special__buttons">
                    <Link className="button" to={offer.btns[3].slug}>VIEW INVENTORY</Link>
                </div>
                {!placeholder
                    && <p className="program">{offer.sales_program}</p>}
                {!placeholder
                    && <p className="expiration">Expires {formatDate(offer.expiration)}</p>}
                {(!placeholder && offer.btns[2].model !== "DIRECTIONS")
                    && <Link className="link" to={offer.btns[2].slug}>LEARN MORE</Link>}
            </div>
            <div className="special__disclaimer">
                <div onClick={handleExpand} className="special__disclaimer-dropdown">
                    <p>OFFER DETAILS</p>
                </div>
                <div className="special__disclaimer-content contract">
                    <p>{(offer.down > 0 && offer.down.toLocaleString()) && `$${offer.down > 0 && offer.down.toLocaleString()} Due at signing. Includes down payment, no security deposit required; excludes tax, title, license, and dealer fees. For well-qualified customers.`} {offer.disclaimer}</p>
                </div>
            </div>
        </div>
    )
}

export default OfferCard
